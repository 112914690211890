<template>
  <div>
    <h1>This is page Statistics</h1>
  </div>
</template>
<script>
export default {
  name: 'Statistics',
}
</script>
